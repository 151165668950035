import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home/:usuario/:senha/:idDoDeposito',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'supervisorio',
    loadChildren: () => import('./pages/supervisorio/supervisorio.module').then( m => m.SupervisorioPageModule)
  },
  {
    path: 'abelha-rainha',
    loadChildren: () => import('./pages/abelha-rainha/abelha-rainha.module').then( m => m.AbelhaRainhaPageModule)
  },
  {
    path: 'supervisorioresitrat',
    loadChildren: () => import('./pages/supervisorioresitrat/supervisorioresitrat.module').then( m => m.SupervisorioresitratPageModule)
  },
  {
    path: 'home-mobile',
    loadChildren: () => import('./pages/home-mobile/home-mobile.module').then( m => m.HomeMobilePageModule)
  },
  {
    path: 'abelha-rainha2',
    loadChildren: () => import('./pages/abelha-rainha2/abelha-rainha2.module').then( m => m.AbelhaRainha2PageModule)
  },
  {
    path: 'chateau',
    loadChildren: () => import('./pages/chateau/chateau.module').then( m => m.ChateauPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
